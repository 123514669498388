import { AppBar, Toolbar, Box, IconButton, Stack, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import ArticleIcon from '@mui/icons-material/Article'; // for Medium
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'; // for TikTok
import LiveTvIcon from '@mui/icons-material/LiveTv'; // for Twitch
import { Discord } from '@mui/icons-material';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',
  padding: '20px 0',
}));

const HeaderContent = styled(Box)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '12px',
  backdropFilter: 'blur(10px)',
  width: '100%',
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));

// Placeholder image URLs
const PLACEHOLDER_IMAGES = {
  logo: '/img/features/ParadiseTycoon_logo.webp',
  icons: {
    discord: 'https://placehold.co/24x24/5865F2/white.png?text=D',
    twitter: 'https://placehold.co/24x24/1DA1F2/white.png?text=T',
    telegram: 'https://placehold.co/24x24/229ED9/white.png?text=TG',
    tiktok: 'https://placehold.co/24x24/000000/white.png?text=TT'
  }
};

// Add Discord SVG icon as a component
const DiscordIcon = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
    <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0 12.64 12.64 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057 19.9 19.9 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028 14.09 14.09 0 0 0 1.226-1.994.076.076 0 0 0-.041-.106 13.107 13.107 0 0 1-1.872-.892.077.077 0 0 1-.008-.128 10.2 10.2 0 0 0 .372-.292.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.118.098.246.198.373.292a.077.077 0 0 1-.006.127 12.299 12.299 0 0 1-1.873.892.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z"/>
  </svg>
);

// Add X icon component
const XIcon = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </svg>
);

// Social media links
const SOCIAL_LINKS = [
  { platform: 'Discord', icon: DiscordIcon, url: 'https://discord.gg/paradise-tycoon' },
  { platform: 'X', icon: XIcon, url: 'https://x.com/paradisetycoon' },
  { platform: 'Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/paradisetycoon' },
  { platform: 'Telegram', icon: TelegramIcon, url: 'https://t.me/paradisetycoon' },
  { platform: 'TikTok', icon: SmartDisplayIcon, url: 'https://www.tiktok.com/@paradisetycoon' },
];

const Header = () => {
  const handleSocialClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <StyledAppBar position="static">
      <Container maxWidth="lg">
        <HeaderContent>
          <Toolbar disableGutters sx={{ px: 2 }}>
            <Box 
              component="img" 
              src={PLACEHOLDER_IMAGES.logo} 
              alt="Paradise Tycoon" 
              sx={{ 
                height: 40,
                objectFit: 'contain'
              }} 
            />
            <Stack direction="row" spacing={1} sx={{ ml: 'auto' }}>
              {SOCIAL_LINKS.map(({ platform, icon: Icon, url }) => (
                <SocialIconButton
                  key={platform}
                  onClick={() => handleSocialClick(url)}
                  aria-label={platform}
                  size="large"
                >
                  <Icon />
                </SocialIconButton>
              ))}
            </Stack>
          </Toolbar>
        </HeaderContent>
      </Container>
    </StyledAppBar>
  );
};

export default Header; 