import { Box, Container, Typography, Stack, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFooter = styled(Box)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.2)',
  padding: '40px 0',
  marginTop: 'auto',
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: 'white',
  textDecoration: 'none',
  opacity: 0.8,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 1,
    textDecoration: 'none',
  },
}));

const Footer = () => {
  return (
    <StyledFooter>
      <Container maxWidth="lg">
        <Stack spacing={3} alignItems="center">
          <Box
            component="img"
            src="/img/features/ParadiseTycoon_logo.webp"
            alt="Paradise Tycoon"
            sx={{ height: 40 }}
          />
          <Stack direction="row" spacing={3}>
            <FooterLink href="https://paradisetycoon.medium.com/" target="_blank">Blog</FooterLink>
            <FooterLink href="https://discord.gg/paradise-tycoon" target="_blank">Community</FooterLink>
            <FooterLink href="https://play.paradisetycoon.com" target="_blank">Play Now</FooterLink>
          </Stack>
          <Typography variant="body2" color="white" opacity={0.6}>
            © {new Date().getFullYear()} Paradise Tycoon. All rights reserved.
          </Typography>
        </Stack>
      </Container>
    </StyledFooter>
  );
};

export default Footer; 