import { Container, Stack, Box, Card, CardMedia, CardContent, Typography, Fade, Snackbar } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    background: {
      default: '#1976d2', // Blue background like in the image
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          transition: 'none !important',
          WebkitTransition: 'none !important',
          MozTransition: 'none !important',
          msTransition: 'none !important',
          OTransition: 'none !important',
          '&&': {
            transition: 'none !important',
          }
        },
      },
    },
  },
});


// Placeholder image URLs for main content
const PLACEHOLDER_IMAGES = {
  hero: {
    gameplay: '/img/features/gameplay.jpg',
    comingSoon: '/img/features/coming-soon.jpg',
    gameplayTitle: '/img/features/gameplay-title.png',
    comingSoonTitle: '/img/features/coming-soon-title.png'
  },
  downloads: {
    browser: '/img/downloads/play-browser.jpg',
    google: '/img/downloads/play-google.jpg',
    appstore: '/img/downloads/play-appstore.jpg'
  },
  features: {
    video: '/img/features/video-thumb.jpg',
    paradisePass: '/img/features/paradise-pass.jpg',
    paradiseTools: '/img/features/paradise-tools.jpg',
    creator: '/img/features/creator-program.jpg',
    roadmap: '/img/features/roadmap.jpg',
    about: '/img/features/about.jpg',
    videoTitle: '/img/features/video-thumb-title.png',
    paradisePassTitle: '/img/features/paradise-pass-title.png',
    paradiseToolsTitle: '/img/features/paradise-tools-title.png',
    creatorTitle: '/img/features/creator-program-title.png',
    roadmapTitle: '/img/features/roadmap-title.png',
    aboutTitle: '/img/features/about-title.png'
  }
};

// At the top of the file, add a constant for border radius
const BORDER_RADIUS = '24px';
const HOVER_TRANSFORM = 'scale(1.01)';
const TRANSITION_DURATION = '2s';
const CLICK_DELAY = 150; // milliseconds before navigation


// Add these style constants near the other constants
const cardContentStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  overflow: 'hidden'
};


const cardSubtitleStyle = {
  fontFamily: '"Luckiest Guy", cursive',
  color: '#F7F3E9',
  fontSize: '1.26rem',
  letterSpacing: '0.05em',
  lineHeight: '1.2',
};

// Simplify titleImageStyle to just handle sizing and spacing
const titleImageStyle = {
  height: '32px',
  maxWidth: '100%',
  width: 'auto',
  objectFit: 'contain',
  marginBottom: '12px',
  display: 'block',
};


const specialTitleImageStyle = {
  height: '150px',
  maxWidth: '100%',
  width: 'auto',
  objectFit: 'contain',
  marginBottom: '12px',
  display: 'block',
};

// Add a wrapper style for title images
const titleWrapperStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  overflow: 'hidden' // Prevent content overflow
};

// Add this constant with all external links
const EXTERNAL_LINKS = {
  gameplay: 'https://docs.google.com/presentation/d/1ZNYFvmrOeQVyzrUcmemeJScCX7-8bmIt',
  video: 'https://www.youtube.com/embed/IzchEE3W3Ok',
  browser: 'https://play.paradisetycoon.com',
  google: 'https://play.google.com/store/apps/details?id=games.empires.paradisetycoon',
  appstore: 'https://testflight.apple.com/join/sQ1hbXvC',
  comingSoon: '/coming-soon',
  roadmap: 'https://paradisetycoon.medium.com/the-journey-ahead-roadmap-2025-ccb9ffec4336',
  creator: 'https://paradisetycoon.medium.com/the-creator-update-is-here-b8dc3391573a',
  paradisePass: 'https://sphere.market/immutable/collection/0xAFc652a4Fba298080B321f6bCb44C230C95Ff939',
  paradiseTools: 'https://sphere.market/immutable/collection/0xb83EcD0624B433DE49f89a004bB67315D87c99cE',
  twitter: 'https://twitter.com/paradisetycoon',
  facebook: 'https://www.facebook.com/paradisetycoon',
  telegram: 'https://t.me/paradisetycoon',
  medium: 'https://paradisetycoon.medium.com/',
  discord: 'https://discord.gg/paradise-tycoon',
  tiktok: 'https://www.tiktok.com/@paradisetycoon',
  twitch: 'https://twitch.com/empiresnotvampires/',
  team: 'https://empires.games'
};

// Update background pattern style
const backgroundPattern = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: 'url(/img/features/ui_base_pattern.png)',
  backgroundRepeat: 'repeat',
  backgroundSize: '800px',
  opacity: 0.05,
  zIndex: 0,
  pointerEvents: 'none',
};

function App() {
  const [videoOpen, setVideoOpen] = useState(false);
  const [slidesOpen, setSlidesOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [loaded, setLoaded] = useState({
    hero1: false,
    hero2: false,
    downloads: false,
    video: false,
    pass: false,
    tools: false,
    creator: false,
    roadmap: false,
    about: false
  });

  useEffect(() => {
    const delays = {
      hero1: 100,
      hero2: 200,
      downloads: 300,
      video: 400,
      pass: 500,
      tools: 600,
      creator: 700,
      roadmap: 800,
      about: 900
    };

    Object.entries(delays).forEach(([key, delay]) => {
      setTimeout(() => {
        setLoaded(prev => ({ ...prev, [key]: true }));
      }, delay);
    });
  }, []);

  const handleVideoOpen = () => {
    setVideoOpen(true);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  const handleSlidesOpen = () => {
    setSlidesOpen(true);
  };

  const handleSlidesClose = () => {
    setSlidesOpen(false);
  };

  // Add this common card style object
  const commonCardStyle = {
    borderRadius: BORDER_RADIUS,
    overflow: 'hidden',
    transform: 'scale(1)',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    position: 'relative',
    '&:hover': {
      transform: HOVER_TRANSFORM,
      boxShadow: '0 8px 24px rgba(0,0,0,0.15)',
    },
    '&:active': {
      transform: 'scale(0.98)',
      transition: 'transform 0.1s ease-in-out',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255,255,255,0.1)',
      opacity: 0,
      transition: `opacity ${TRANSITION_DURATION} cubic-bezier(0.4, 0, 0.2, 1)`,
      pointerEvents: 'none'
    },
    '&:active::after': {
      opacity: 1,
      transition: 'opacity 0.1s ease-in-out'
    }
  };

  // Add click handler function
  const handleCardClick = (e, href) => {
    e.preventDefault();
    // Flash effect is handled by CSS
    setTimeout(() => {
      window.location.href = href;
    }, CLICK_DELAY);
  };

  const handleComingSoonClick = (e) => {
    e.preventDefault();
    setToastOpen(true);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        bgcolor: 'background.default',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative', // Add this to contain the absolute positioned overlay
      }}>
        {/* Add the pattern overlay */}
        <Box sx={backgroundPattern} />

        {/* Existing content with higher z-index */}
        <Box sx={{ position: 'relative', zIndex: 1, display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Header />
          <Container maxWidth="lg" sx={{ py: 4 }}>
            {/* Hero Section */}
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mb: 4 }}>
              <Box sx={{ width: { xs: '100%', md: '66.66%' } }}>
                <Fade in={loaded.hero1} timeout={1000} mountOnEnter unmountOnExit>
                  <div>
                    <Card
                      sx={{ ...commonCardStyle, height: 400 }}
                      onClick={handleSlidesOpen}
                    >
                      <Box sx={{ position: 'relative', height: '100%' }}>
                        <CardMedia
                          component="img"
                          image={PLACEHOLDER_IMAGES.hero.gameplay}
                          alt="Gameplay"
                          sx={{ height: '100%', objectFit: 'cover' }}
                        />
                        <CardContent sx={{ ...cardContentStyle, width: '50%' }}>
                          <Box sx={titleWrapperStyle}>
                            <Box
                              component="img"
                              src={PLACEHOLDER_IMAGES.hero.gameplayTitle}
                              alt="GAMEPLAY"
                              sx={specialTitleImageStyle}
                            />
                          </Box>
                          <Typography variant="subtitle1" sx={cardSubtitleStyle}>
                            Find out more about the unique multiplayer life simulation game everyone's talking about!
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  </div>
                </Fade>
              </Box>
              <Box sx={{ width: { xs: '100%', md: '33.33%' } }}>
                <Fade in={loaded.hero2} timeout={1000} mountOnEnter unmountOnExit>
                  <div>
                    <Card
                      sx={{ ...commonCardStyle, height: 400 }}
                      onClick={handleComingSoonClick}
                    >
                      <Box sx={{ position: 'relative', height: '100%' }}>
                        <CardMedia
                          component="img"
                          image={PLACEHOLDER_IMAGES.hero.comingSoon}
                          alt="Coming Soon"
                          sx={{ height: '100%', objectFit: 'cover' }}
                        />
                        <CardContent sx={cardContentStyle}>
                          <Box sx={titleWrapperStyle}>
                            <Box
                              component="img"
                              src={PLACEHOLDER_IMAGES.hero.comingSoonTitle}
                              alt="COMING SOON"
                              sx={titleImageStyle}
                            />
                          </Box>
                          <Typography variant="subtitle1" sx={cardSubtitleStyle}>
                            AI Arrives to Paradise Tycoon
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  </div>
                </Fade>
              </Box>
            </Stack>

            {/* Download Buttons */}
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              {['browser', 'google', 'appstore'].map((platform, index) => (
                <Box key={platform} sx={{ width: { xs: '100%', sm: '33.33%' } }}>
                  <Fade in={loaded.downloads} timeout={1000} mountOnEnter unmountOnExit>
                    <div>
                      <Card
                        component="a"
                        href={EXTERNAL_LINKS[platform]}
                        onClick={(e) => handleCardClick(e, EXTERNAL_LINKS[platform])}
                        sx={{ ...commonCardStyle, height: 48, bgcolor: 'black' }}
                      >
                        <CardMedia
                          component="img"
                          image={PLACEHOLDER_IMAGES.downloads[platform]}
                          alt={`Get it on ${platform.charAt(0).toUpperCase() + platform.slice(1)}`}
                          sx={{
                            height: '100%',
                            objectFit: 'contain',
                            borderRadius: BORDER_RADIUS,
                          }}
                        />
                      </Card>
                    </div>
                  </Fade>
                </Box>
              ))}
            </Stack>

            {/* Features Section */}
            <Stack spacing={2}>
              {/* Video Section */}
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                  <Fade in={loaded.video} timeout={1000} mountOnEnter unmountOnExit>
                    <div>
                      <Card
                        sx={{ ...commonCardStyle, height: 280 }}
                        onClick={handleVideoOpen}
                      >
                        <Box sx={{ position: 'relative', height: '100%' }}>
                          <CardMedia
                            component="img"
                            image={PLACEHOLDER_IMAGES.features.video}
                            alt="Watch the Video"
                            sx={{ height: '100%', objectFit: 'cover' }}
                          />
                          <CardContent sx={cardContentStyle}>
                            <Box sx={titleWrapperStyle}>
                              <Box
                                component="img"
                                src={PLACEHOLDER_IMAGES.features.videoTitle}
                                alt="THIS IS PARADISE TYCOON"
                                sx={specialTitleImageStyle}
                              />
                            </Box>
                            <Typography variant="subtitle1" sx={cardSubtitleStyle}>
                              Watch the Video!
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    </div>
                  </Fade>
                </Box>

                {/* Paradise Pass & Tools */}
                <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
                      <Fade in={loaded.pass} timeout={1000} mountOnEnter unmountOnExit>
                        <div>
                          <Card
                            sx={{ ...commonCardStyle, height: 280 }}
                            onClick={(e) => handleCardClick(e, EXTERNAL_LINKS.paradisePass)}
                          >
                            <Box sx={{ position: 'relative', height: '100%' }}>
                              <CardMedia
                                component="img"
                                image={PLACEHOLDER_IMAGES.features.paradisePass}
                                alt="Paradise Pass"
                                sx={{ height: '100%', objectFit: 'cover' }}
                              />
                              <CardContent sx={cardContentStyle}>
                                <Box sx={titleWrapperStyle}>
                                  <Box
                                    component="img"
                                    src={PLACEHOLDER_IMAGES.features.paradisePassTitle}
                                    alt="Paradise Pass"
                                    sx={titleImageStyle}
                                  />
                                </Box>
                                <Typography variant="subtitle1" sx={cardSubtitleStyle}>
                                  Explore the NFTs
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        </div>
                      </Fade>
                    </Box>
                    <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
                      <Fade in={loaded.tools} timeout={1000} mountOnEnter unmountOnExit>
                        <div>
                          <Card
                            sx={{ ...commonCardStyle, height: 280 }}
                            onClick={(e) => handleCardClick(e, EXTERNAL_LINKS.paradiseTools)}
                          >
                            <Box sx={{ position: 'relative', height: '100%' }}>
                              <CardMedia
                                component="img"
                                image={PLACEHOLDER_IMAGES.features.paradiseTools}
                                alt="Paradise Tools"
                                sx={{ height: '100%', objectFit: 'cover' }}
                              />
                              <CardContent sx={cardContentStyle}>
                                <Box sx={titleWrapperStyle}>
                                  <Box
                                    component="img"
                                    src={PLACEHOLDER_IMAGES.features.paradiseToolsTitle}
                                    alt="Paradise Tools"
                                    sx={titleImageStyle}
                                  />
                                </Box>
                                <Typography variant="subtitle1" sx={cardSubtitleStyle}>
                                  Explore the NFTs
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        </div>
                      </Fade>
                    </Box>
                  </Stack>
                </Box>
              </Stack>

              {/* Creator Program & Roadmap/About Section */}
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                  <Fade in={loaded.creator} timeout={1000} mountOnEnter unmountOnExit>
                    <div>
                      <Card
                        sx={{ ...commonCardStyle, height: 650 }}
                        onClick={(e) => handleCardClick(e, EXTERNAL_LINKS.creator)}
                      >
                        <Box sx={{ position: 'relative', height: '100%' }}>
                          <CardMedia
                            component="img"
                            image={PLACEHOLDER_IMAGES.features.creator}
                            alt="Creator Program"
                            sx={{ height: '100%', objectFit: 'cover' }}
                          />
                          <CardContent sx={{ ...cardContentStyle, justifyContent: 'flex-start' }}>
                            <Box sx={titleWrapperStyle}>
                              <Box
                                component="img"
                                src={PLACEHOLDER_IMAGES.features.creatorTitle}
                                alt="Creator Program"
                                sx={specialTitleImageStyle}
                              />
                            </Box>
                            <Typography variant="subtitle1" sx={cardSubtitleStyle}>
                              Learn how to create your own events in Paradise Tycoon
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    </div>
                  </Fade>
                </Box>

                <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                  <Stack spacing={2}>
                    <Box>
                      <Fade in={loaded.roadmap} timeout={1000} mountOnEnter unmountOnExit>
                        <div>
                          <Card
                            sx={{ ...commonCardStyle, height: 315 }}
                            onClick={(e) => handleCardClick(e, EXTERNAL_LINKS.roadmap)}
                          >
                            <Box sx={{ position: 'relative', height: '100%' }}>
                              <CardMedia
                                component="img"
                                image={PLACEHOLDER_IMAGES.features.roadmap}
                                alt="Roadmap"
                                sx={{ height: '100%', objectFit: 'cover' }}
                              />
                              <CardContent sx={cardContentStyle}>
                                <Box sx={titleWrapperStyle}>
                                  <Box
                                    component="img"
                                    src={PLACEHOLDER_IMAGES.features.roadmapTitle}
                                    alt="Roadmap"
                                    sx={titleImageStyle}
                                  />
                                </Box>
                                <Typography variant="subtitle1" sx={cardSubtitleStyle}>
                                  Discover the exciting new features and expansions coming soon!
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        </div>
                      </Fade>
                    </Box>
                    <Box>
                      <Fade in={loaded.about} timeout={1000} mountOnEnter unmountOnExit>
                        <div>
                          <Card 
                            sx={{ ...commonCardStyle, height: 315 }}
                            onClick={(e) => handleCardClick(e, EXTERNAL_LINKS.team)}
                          >
                            <Box sx={{ position: 'relative', height: '100%' }}>
                              <CardMedia
                                component="img"
                                image={PLACEHOLDER_IMAGES.features.about}
                                alt="About Us"
                                sx={{ height: '100%', objectFit: 'cover' }}
                              />
                              <CardContent sx={cardContentStyle}>
                                <Box sx={titleWrapperStyle}>
                                  <Box
                                    component="img"
                                    src={PLACEHOLDER_IMAGES.features.aboutTitle}
                                    alt="About Us"
                                    sx={titleImageStyle}
                                  />
                                </Box>
                                <Typography variant="subtitle1" sx={cardSubtitleStyle}>
                                  Meet the Team!
                                </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        </div>
                      </Fade>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Stack>

            {/* Video Dialog */}
            <Dialog
              fullWidth
              maxWidth="md"
              open={videoOpen}
              onClose={handleVideoClose}
              slotProps={{
                paper: {
                  sx: {
                    width: '60vw',
                    height: '60vh',
                    maxWidth: 'none',
                    m: 0
                  }
                }
              }}
            >
              <IconButton
                sx={{ position: 'absolute', right: 8, top: 8, color: 'white', zIndex: 1 }}
                onClick={handleVideoClose}
              >
                <CloseIcon />
              </IconButton>
              <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  src={`${EXTERNAL_LINKS.video}?autoplay=1&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3&disablekb=1`}
                  title="Paradise Tycoon Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Box>
            </Dialog>

            {/* Slides Dialog */}
            <Dialog
              fullWidth
              maxWidth="lg"
              open={slidesOpen}
              onClose={handleSlidesClose}
              slotProps={{
                paper: {
                  sx: {
                    width: '80vw',
                    height: '80vh',
                    maxWidth: 'none',
                    m: 0
                  }
                }
              }}
            >
              <IconButton
                sx={{ position: 'absolute', right: 8, top: 8, color: 'white', zIndex: 1 }}
                onClick={handleSlidesClose}
              >
                <CloseIcon />
              </IconButton>
              <Box sx={{ position: 'relative', height: '100%' }}>
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 'none'
                  }}
                  src={`${EXTERNAL_LINKS.gameplay}/embed?start=false&loop=false&delayms=3000`}
                  title="Gameplay Presentation"
                  allowFullScreen
                />
              </Box>
            </Dialog>

            {/* Add Snackbar for toast notification */}
            <Snackbar
              open={toastOpen}
              autoHideDuration={3000}
              onClose={handleToastClose}
              message="AI is coming to Paradise Tycoon soon!"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
          </Container>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
